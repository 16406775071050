import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react"

interface ILogoList {
  title: string;
  logos: any[];
}

const LogoList = ({data}: {data: ILogoList}) => {    
  console.log(data);
  
  return (
    <section className="container mx-auto py-16">
        <div className="text-center mb-8">
            <h1>{data.title}</h1>
        </div>
        <div className="flex flex-wrap gap-16 justify-center">
            {data.logos.map((image: any, index: number) => (
                <GatsbyImage
                    key={index}
                    image={getImage(image.localFile)}
                    alt={image.alternativeText}
                    className="h-16"
                />
            ))}
        </div>
    </section>
  )
}

export default LogoList
import React from "react"
import '../styles/block-rich-text.scss'

const BlockRichText = ({ data }: any) => {
  return (
    <div
      className="block-rich-text"
      dangerouslySetInnerHTML={{
        __html: data.body.data.childMarkdownRemark.html,
      }}
    />
  )
}

export default BlockRichText
import * as React from "react"
import { graphql, Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import { FiFacebook, FiLink, FiLinkedin, FiTwitter } from "react-icons/fi"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PageRenderer from "../components/page-renderer"
import Seo from "../components/seo"

const CasePage = ({ data }: any) => {  
  const project = data.strapiCase
  const projectUrl = 'https://deltafuturum.nl/cases/' + project.slug  

  return (
    <>
      {/* SEO */}
      <Seo seo={{
        metaTitle: project.title,
        metaDescription: project.description,
        shareImage: project.cover?.localFile.url
      }}/>

      {/* Navigation */}
      <Header/>

      {/* Page Title */}
      <section className="container mx-auto grid grid-cols-12 gap-x-6 items-start">
      	<div className="col-span-12 md:col-end-11 col-start-1 row-start-1 px-6 md:px-0">
          <div className="grid grid-cols-10 gap-4 py-16 bg-fill-left bg-brand-blue before:bg-brand-blue">
            <h1 className="col-span-10 md:col-span-5 text-white">{project.title}</h1>
          </div>
          <div className="grid grid-cols-10 gap-6 py-16 bg-brand-blue-light bg-fill-right after:bg-brand-blue-light">
            <div className="md:col-end-6 col-start-2 col-span-9">
              <h3 className="text-2xl font-bold text-brand-blue">Opdrachtgever</h3>
              <p>{project.client}</p>
            </div>
            <div className="md:col-end-6 col-start-2 col-span-9">
              <h3 className="text-2xl font-bold text-brand-blue">Diensten</h3>
              <p>
                {project.services.map(({ title, slug }: any, index: number) => (
                  <>
                    <Link to={`/diensten/${slug}`} className="hover:text-brand-green transition-colors"> {title}</Link>
                    {index !== project.services.length - 1 ? "," : ""}
                  </>
                ))}
              </p>
            </div>
            <div className="md:col-end-6 col-start-2 col-span-9">
              <h3 className="text-2xl font-bold text-brand-blue">Datum</h3>
              <p>{project.createdAt}</p>
            </div>
          </div>
        </div>
        <div className="md:col-span-7 md:col-start-6 md:row-start-1 col-span-12 md:py-16">
          <div className="img-deco-right">
            <GatsbyImage
              image={getImage(project.cover?.localFile)}
              alt={project.cover?.alternativeText}
            />
          </div>
        </div>
      </section>

      <section className="container mx-auto flex flex-col md:grid grid-cols-12 py-16 gap-x-6 gap-y-6 px-6 md:px-0">
        <h2 className="col-span-4 col-start-2">{project.slogan}</h2>  
        <p className="col-span-6 col-start-6">
          {project.description}
        </p>
      </section>

      <section className="max-w-prose mx-auto px-6">
        <PageRenderer blocks={project.blocks}/>
      </section>

      {/* Share */}
      <div className="p-12 mb-16 bg-brand-blue-light mx-auto max-w-prose flex flex-wrap items-center gap-6 text-brand-blue">
        <h3 className="text-2xl font-bold mr-auto w-full sm:w-auto">Dit project delen</h3>
        <a href={"https://twitter.com/share?url=" + projectUrl + "&text=" + project.title + "&via=deltafuturum"}>
          <FiTwitter size="24" className="hover:text-brand-green transition-colors cursor-pointer"/>
        </a>
        <a href={"https://www.facebook.com/sharer.php?u=" + projectUrl}>
          <FiFacebook size="24" className="hover:text-brand-green transition-colors cursor-pointer"/>
        </a>
        <a href={"https://www.linkedin.com/shareArticle?url=" + projectUrl + "&title=" + project.title}>
          <FiLinkedin size="24" className="hover:text-brand-green transition-colors cursor-pointer"/>
        </a>
        <a href="javascript:void(0)">
          <FiLink size="24" className="hover:text-brand-green transition-colors cursor-pointer"/>
        </a>
      </div>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiCase(slug: { eq: $slug }) {
      id
      slug
      title
      client
      slogan
      description
      url
      services {
        title
        slug
      }
      blocks {
        ...CaseBlocks
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      createdAt(formatString: "DD-MM-YYYY")
    }
  }
`

export default CasePage
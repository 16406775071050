import { graphql } from "gatsby";
import React from "react";
import BlockRichText from "./block-rich-text";
import Hero from "./hero";
import ImageSection from "./image-section";
import LogoList from "./logo-list";
import PageTitle from "./page-title";

const componentsMap = {
  STRAPI__COMPONENT_SHARED_PAGE_TITLE: PageTitle,
  STRAPI__COMPONENT_SHARED_IMAGE_SECTION: ImageSection,
  STRAPI__COMPONENT_SHARED_HERO: Hero,
  STRAPI__COMPONENT_SHARED_LOGO_LIST: LogoList,
  STRAPI__COMPONENT_SHARED_RICH_TEXT: BlockRichText
}

const Block = ({ block }: any) => {
  const Component = componentsMap[block.__typename]

  if (!Component) {
    return null
  }

  return <Component data={block} />
}

const PageRenderer = ({ blocks }: any) => {  
  console.log(blocks);
  
  return (
    <>
      {blocks.map((block: any, index: number) => (
        <Block key={`${index}${block.__typename}`} block={block} />
      ))}
    </>
  )
}

export const query = graphql`
  fragment ServicePageBlocks on STRAPI__COMPONENT_SHARED_HEROSTRAPI__COMPONENT_SHARED_IMAGE_SECTIONSTRAPI__COMPONENT_SHARED_LOGO_LISTUnion {
    __typename
    ... on STRAPI__COMPONENT_SHARED_HERO {
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      content {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_IMAGE_SECTION {
      reverse
      transparent
      content {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_LOGO_LIST {
      title
      logos {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }

  fragment CaseBlocks on STRAPI__COMPONENT_SHARED_RICH_TEXT {
    __typename
    ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
      body {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default PageRenderer

// ... on STRAPI__COMPONENT_SHARED_PAGE_TITLE {
//   title
//   description
//   image {
//     alternativeText
//     localFile {
//       childImageSharp {
//         gatsbyImageData
//       }
//     }
//   }
// }